<template>

<div id="">
  <v-layout wrap>
    <v-flex xs12 class="search-row">
      <v-layout  wrap>
        <v-flex class="px-4" xs12>
          <Navbar navbarClass="transparent"></Navbar>
        </v-flex>
        <!-- <v-flex xs12 fluid class="px-4 py-2 headline info-bar">
          FREE RESERVATION
        </v-flex>
        <v-flex xs12 sm5 class="align-center justify-center coupon-card">
          <v-layout wrap justify-center justify-space-around>
            <v-card class="card-width" >
              <v-card-text style="text-align:center;">
                <div class=" justify-center coupon-title">
                  No reservation fee on all bookings
                </div>
                <div class=" justify-center coupon-text">
                use coupon: <b>APRIL20FREE</b>
                </div>
                <div class="coupon-deadline">
                  ends April 26th 2020
                </div>

                <div class="">
                  <v-btn @click="seeLisitingsCoupon" flat  color="primary">see listings</v-btn>
                </div>
              </v-card-text> -->
              <!-- <v-card-actions >
                <v-btn color="primary" style="margin:auto">see Rooms</v-btn>
              </v-card-actions> -->
            <!-- </v-card>
          </v-layout>
        </v-flex> -->
        <v-flex xs12 class="ma-auto">
          <v-layout wrap>
            <!-- <v-flex xs11 sm8 class=" px-4 title"> -->
            <v-flex xs10  class=" title ">
                <h1>
                {{$t("homepage.title")}}
              </h1>
            </v-flex>
            <!-- <v-flex xs11 sm8  class="search-bar"> -->
            <v-flex sm7 xs11 class="search-bar " >
              <v-layout align-center justify-space-between wrap class="">
                <!-- <v-flex sm6 xs12 class="pr-2 layout align-center margin-left justify-space-between"> -->
                <v-flex xs12 sm5 class="pr-2 layout align-center margin-left justify-space-between">

                  <v-autocomplete :loading="loading" @change="setPlace" :auto-select-first="true" v-model="search" :filter="searchFilter" :items="loc" item-value="name" prepend-icon="search" return-object clearable :placeholder="$t('homepage.enterLocation')">
                    <template slot="no-data">
                      <v-list-tile>
                        <v-list-tile-title>
                          Nothing result found
                        </v-list-tile-title>
                      </v-list-tile>
                    </template>

                    <template slot="selection" slot-scope="{item, selected}">
                      <v-icon>{{item.icon}}</v-icon>
                      <span class="pl-2">{{item.name}}</span>
                    </template>
                    <template
                    slot="item"
                    slot-scope="{ item, tile }"
                    >
                    <v-icon>{{item.icon}}</v-icon>
                    <span class="pl-2">{{item.name}}</span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex xs12 sm2 class="px-1 left-border top-border-xs">
              <!-- <v-flex sm2 xs12 class="px-1 left-border top-border-xs"> -->
                <v-menu
                :close-on-content-click="false"
                v-model="dateMenu"
                :nudge-right="40"
                lazy
                full-width
                class="px-1 "
                transition="scale-transition"
                >
                <v-text-field
                slot="activator"
                v-model="date"
                :label="$t('resultsPage.dates')"
                prepend-icon="event"
                readonly
                ></v-text-field>
                <v-date-picker v-model="date" @change="setDateQuery()" @input="dateMenu = false"></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm2 class="px-1 pr-1 left-border ">
            <!-- <v-flex sm2 xs12 class="px-1 pr-1 left-border "> -->
              <v-select
              :items="roomItems"
              v-model="roomNumber"
              :label="$t('resultsPage.numberOfRooms')"
              class="px-1"
              prepend-icon="meeting_room"
              @change="setRoomQuery"
              ></v-select>
            </v-flex>
            <v-flex xs12 sm2 class="">
            <!-- <v-flex sm2 xs12 class=""> -->
              <router-link :to="{
                name: pathName,
                query: query
              }"
              >
              <v-btn color="primary" class="search-btn">
                {{$t('homepage.search')}}
              </v-btn>
            </router-link>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    </v-flex>
    <v-flex xs12>
    <v-layout  wrap class="trust">
      <v-flex xs12 class="trust-heading headline">
        <h5>
          {{$t('homepage.trust')}}
        </h5>
      </v-flex>
      <v-flex xs6 sm3 class="trust-logos headline">
        <router-link :to="toNTNU " id="NTNULink">
          <h6> {{$t('homepage.ntnu')}} </h6>
        </router-link>
      </v-flex>
      <v-flex xs6 sm3 class="trust-logos headline">
        <h6>
          <router-link :to="toNTUST" id="NTUSTLink">{{$t('homepage.ntust')}}</router-link>
        </h6>
      </v-flex>
      <v-flex xs6 sm3 class="trust-logos headline">
        <h6>
          <router-link :to="toNCCU" id="NCCULink">{{$t('homepage.nccu')}}</router-link>
        </h6>

      </v-flex>
      <v-flex xs6 sm3 class="trust-logos headline">
        <h6>
          <router-link :to="toTaipeiTech" id="RtTLink">{{$t('homepage.taipeiTech')}}</router-link>
        </h6>

      </v-flex>
    </v-layout>
  </v-flex>

          </v-layout>
        </v-flex>
  </v-layout>
  <v-layout wrap class="booking-steps">
    <v-flex xs12 class="booking-steps-header">
      <h2 class="headline pa-2">{{$t('homepage.whyBook')}}</h2>
    </v-flex>
    <v-flex xs12 md9 class="booking-steps-text">
      <p>{{$t('homepage.whyBookText')}}</p>

    </v-flex>
  </v-layout>

  <!-- <v-container grid-list-xl class="no-pa-top "> -->
    <v-layout wrap class="no-pa-top">
      <v-flex xs12 sm4 class="booking-steps-list-item">

        <v-img class="booking-steps-list-step-image" alt="Find your place and book it" src='https://res.cloudinary.com/my-room-abroad/image/fetch/q_auto,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/find_room.jpg' width="200" height="200">
        </v-img>
        <p>{{$t('homepage.whyBookStep1')}}</p>
      </v-flex>
      <v-flex xs12 sm4 class="booking-steps-list-item">
        <v-img class="booking-steps-list-step-image" alt="Wait for confirmation" src='https://res.cloudinary.com/my-room-abroad/image/fetch/f_auto,q_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/confirm_room.jpg' width="200" height="200">
        </v-img>
        <p>{{$t('homepage.whyBookStep2')}}</p>

      </v-flex>
      <v-flex xs12 sm4 class="booking-steps-list-item">
        <v-img class="booking-steps-list-step-image" alt="Move in and live it!" src='https://res.cloudinary.com/my-room-abroad/image/fetch/q_auto,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/move_in.jpg' width="200" height="200">
        </v-img>
        <p>{{$t('homepage.whyBookStep3')}}</p>

      </v-flex>

    </v-layout>
  <!-- </v-container>
  <v-container grid-list-xl fluid class="data-info no-overflow"> -->
    <v-layout wrap justify-center class="data-info">
      <v-flex xs12 sm4 >
        <h5  class="headline dark-bg">{{$t('homepage.moreThan')}} <br> <strong>{{$t('homepage.students')}}</strong></h5>
      </v-flex>
      <v-flex xs12 sm4 class="booking-steps-list-item">
        <h5 class="dark-bg headline">{{$t('homepage.fromOver')}} <br> <strong>{{$t('homepage.countries')}}</strong></h5>

      </v-flex>
      <v-flex xs12 sm4 class="booking-steps-list-item">
        <h5 class="dark-bg headline">{{$t('homepage.book')}} <br> <strong>{{$t('homepage.places')}}</strong></h5>

      </v-flex>

    </v-layout>
  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout  wrap align-center class="ma-4 ">

      <v-flex xs12 sm9 class="imageContainer">
        <v-img alt="My Room Abroad Team" contain max-height="60vh" class="mra-team-pic" src="https://res.cloudinary.com/my-room-abroad/image/fetch/w_1400,q_auto,f_auto/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/team2.jpg">
        </v-img>
      </v-flex>
      <v-flex xs12 sm3 class="text-xs-center">
        <h2 class="team-heading headline">{{$t('homepage.teamTitle')}}</h2>
        <p class="team__copy">{{$t('homepage.teamText')}}</p>
        <v-btn color="primary"  @click="$router.push({ name: 'aboutUs', query:query})">{{$t('homepage.about')}}</v-btn>
      </v-flex>
    </v-layout>

  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout justify-center wrap class="ma-4">
      <v-flex justify-center xs12>

        <h2 class="landlord-area__heading headline">{{$t('homepage.landlordTitle')}}</h2>
      </v-flex>
      <v-flex justify-center xs12 sm6>
        <p class="landlord-area__intro">{{$t('homepage.landlordText')}}</p>
      </v-flex>
      <v-flex xs12 justify-center class="text-xs-center">
        <v-btn flat color="primary" href='/signup' >
          {{$t('homepage.landlordBtn')}}
        </v-btn>
      </v-flex>
    </v-layout>
  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout wrap>
      <v-flex xs12>
        <h2 class="landlord-area__heading ma-2 headline">
          Search for any type of listing
        </h2>
      </v-flex>
      <v-flex xs12 class="pa-3" sm4 v-for="(item, index) in rooms" :key="index">
        <v-card hover :href='item.link'>
          <v-img
            cover
            aspect-ratio="1.78"
            :src="item.photos[randomized[index]]"
          >
          </v-img>
          <!-- {{index}} -->
          <!-- {{item}} -->
          <!-- {{randomized[index]}} -->
          <v-card-title primary-title>
            {{item.title}}
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout  wrap>
      <v-flex xs12>
        <h2 class="ma-2 landlord-area__heading headline">The best districts in Taipei</h2>
      </v-flex>
      <div class="" style="max-width:100vw">

      <v-tabs
           v-model="tab"
           dark
           grow
           color="primary"
           width="100%"
         >
           <v-tabs-slider color="white"></v-tabs-slider>

           <v-tab
            dense
              v-for="(area,index) in areas"
             :key="index"
             :tab="area.val"

           >
             {{area.title}}

           </v-tab>

           <v-tab-item
             v-for="(area,index) in areas"
             :key="index"
             :value="area.val"
           >
           <v-layout wrap justify-center>
             <v-flex xs12 sm10 md9 lg8 xl7>
               <v-card
               flat
               tile
               >
               <v-card-text><Area :noButton="false" :area="area.val"></Area></v-card-text>
             </v-card>
             </v-flex>
           </v-layout>
           </v-tab-item>
         </v-tabs>


         </div>
    </v-layout>
  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout wrap pa-2>
      <v-flex xs12>
        <h2 class="ma-2 landlord-area__heading headline">Join us on one of our events</h2>
      </v-flex>
      <v-flex xs12 sm4 class="pa-3" v-for="(ev,index) in events" :key="index">
        <v-card href="/events" hover>
          <v-img
            cover
            aspect-ratio="1.78"
            :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1565684569/'+ev.img[0].id"
          >
          </v-img>
          <v-card-title>
            {{ev.title}}
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>

  <!-- </v-container>
  <v-container grid-list-xl> -->
    <v-layout class="pa-2 landlord-area__heading" wrap >
      <v-flex xs12 justify-center>
        <h2 class="justify-center headline">
          Discover Taiwan's best kept secrets
        </h2>
      </v-flex>
      <v-flex xs12 pa-3 sm4 justify-center>
          <v-card hover href="https://blog.myroomabroad.com/category/adventure-taiwan/" target="_blank">
            <v-img
              cover
              aspect-ratio="1.78"
              src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/Jiufen-teapot.png"
            >
            </v-img>
            <v-card-title primary-title>
              Adventure in Taiwan
            </v-card-title>
          </v-card>
      </v-flex>
      <v-flex xs12 sm4 class="pa-3">
        <v-card hover href="https://blog.myroomabroad.com/category/life-in-taipei/" target="_blank">
          <v-img
            cover
            aspect-ratio="1.78"
            src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/rainy-day-taipei.jpg"
          >
          </v-img>
          <v-card-title primary-title>
            Life in Taipei
          </v-card-title>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 class="pa-3">

          <v-card hover href="https://blog.myroomabroad.com/category/about-mra/" target="_blank">
            <v-img
              cover
              aspect-ratio="1.78"
              src="https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1567407535/homepage/MRA_TEAM.jpg"
            >
            </v-img>
            <v-card-title primary-title>
              About My Room Abroad
            </v-card-title>
          </v-card>
      </v-flex>
    </v-layout>
  <!-- </v-container> -->
</div>
</template>
<script>
import axios from 'axios'
import firebase from '../FirebaseApp'
import settings from '../config'
import Area from './Area.vue'
export default {
  name: "",
  data: () => ({
    place: "",
    loading:true,
    tab:0,
    areas:[
      {val:'daanStudent', title:"Daan"},
      {val:'yonghe', title:"Yonghe"},
      {val:'zhongzheng', title:"Zhongzheng"},
      {val:'xinyi', title:"Xinyi"},
      {val:'daanExpat', title:"Daan for Expats"},
      {val:'zhongshan', title:"Zhongshan"},
    ],
    dateMenu:false,
    date:null,
    roomNumber:null,
    roomItems:[
      1,2,3,4
    ],
    query:{
    },

    pathName:"roomSearch",
    toNCCU: {
      name: 'roomSearch',
      query: {
        lat: "24.9864564",
        lng: "121.57561980000003",
        name: "National%20Chengchi%20University",
        id: "ff294c66225ea9c6281ba4e12c0a967bf81d2492"
      }
    },
    search:{},
    toNTNU: {
      name: 'roomSearch',
      query: {
        lat: '25.0260598',
        lng: '121.52753210000003',
        name: "National%20Taiwan%20Normal%20University",
        id: "96ee494bd7fde71f430ecd9e7e2a5614f06a5978"
      }
    },
    toNTUST: {
      name: 'roomSearch',
      query: {
        lat: '25.0136906',
        lng: '121.5406792',
        name: "National%20Taiwan%20University%20of%20Science%20and%20Technology",
        id: "6b67d9b3bd8138da5f016c10f1c072aa619673c1"
      }
    },
    toTaipeiTech:{
      name: 'roomSearch',
      query:{
        lat:'25.0430635',
        lng:'121.5346877',
        name:'Taipei%20Tech'

      }
    },
    toREACHTOTEACH: {
      name: 'roomSearch',
      query: {
        name: "Reach%20To%20Teach"
      }
    },
    rooms:[{
      title:"Rooms",
      photos:["https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOufGM14aDe3Q8faEOl/bjzjr0rswdmm5qksjksf",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lfs-XAhAZbcQgXNbekF/rhnfmwhiwnzxxbbv4six",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOu1ubbI4O3cvMSox2o/caftjwr8dvvd2go1sxjv",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lf4GXbYun4_nuus9M10/ldrvigvwufhnux21rvwv",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOu1ubbI4O3cvMSox2o/jwuse5wnmwr3x6p2x3in"
      ],
      link: "/"
    },{
      title:"Studios",
      photos:[
        "https://res.cloudinary.com/my-room-abroad/image/upload/c_scale,h_333,f_auto,q_auto/v1539854631/-LRzd0TKrgheb00DLZ6X/i3h9bebik8xq8rwdikii",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lf4pMi7QapdpRacC7XJ/tihodgd2kirmpfyqh93c",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqFfd0QOKVbDjOMKUq/qosnheftf6iouusznbty",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-Lhii8L4OCPfmhHHLyLE/eklyabxwtfaq3dux03od",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LhEqvKARGfCwW1FLcTc/iz16rykehnjf5pmglr9r"
      ]
    },{
      title:"Apartments",
      photos:[
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/hbh1ndzyzhybma9mjvkd",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/ulazedh48lvsrrnn8nec",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/th9dli0vknpkcx5w8ug9",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqFfd0QOKVbDjOMKUq/pcxf9gvxzp5ubff0maqz",
        "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/-LOqEaHmz54W80lAcNlK/aa2shhamitegpuyrj1kp"
      ],
    }],
  }),
  firebase() {
    return {
      events: {
        source: firebase.database().ref('events').orderByChild('start').limitToLast(3),
        readyCallback: function() {
          console.log(this.events);
        }
      },
      loc:{
        source: firebase.database().ref('locations'),
        readyCallback: function(){
          this.loading=false
          for (var i = 0; i < this.loc.length; i++) {
            switch(this.loc[i].icon){
              case 'room':
                this.loc[i].val=10
                break;
              case 'location_city':
                this.loc[i].val=6
                break
              case 'school':
                this.loc[i].val=8
                break
              case 'local_dining':
                this.loc[i].val=2
                break
              case 'photo_filter':
                this.loc[i].val=3
                break
              case 'directions_transit':
                this.loc[i].val=5
                break
              default:
                this.loc[i].val=1
            }
          }
          this.loc.sort((a, b) => (a.val > b.val) ? -1 : 1)

        }
      }
    }
  },
  computed:{
    randomized(){
      let i=Math.floor(Math.random() * 5)
      let j=Math.floor(Math.random() * 5)
      let k=Math.floor(Math.random() * 5)
      return [i,j,k]
    },
  },
  methods: {
    seeLisitingsCoupon(){
      dataLayer.push({
        'event':'seeListingsCouponClick',
      })
      this.$router.push('/search')
    },
    searchFilter(item, query){

      return item.name.toLowerCase().includes(query.toLowerCase())
    },
    goToSearch() {
      // console.log('execute this')
        this.$router.push({
          name: this.pathName,
          query: this.query
        })
    },
    setPlace(place) {
      console.log(place);
      this.search = place
      this.query.lat= place.lat
      this.query.lng= place.lng
      this.query.zoom=14
      this.query.name= place.name
    },
    setRoomQuery(){
      console.log('set Room',this.roomNumber)
      if (this.roomNumber==null || this.roomNumber==1){
        this.query.aptType=undefined
        this.query.nor=undefined
        this.pathName='roomSearch'
      } else {
        this.query.aptType=undefined
        this.query.nor=this.roomNumber
        this.pathName='aptSearch'
      }
    },
    setDateQuery(){
      console.log('setQueryDate');
      if (this.date!=null){
        this.query.mid=this.date
      }
    },
    checkQuery(){
      // console.log('route: ', this.$route);
      if (this.$route.query!=undefined && this.$route.query.id!=undefined){
        console.log('post the query RTS:',this.$route.query.rts, "+ID: ",this.$route.query.id);
        axios.post('eOpens.json',{
          user:this.$route.query.id,
          rts: this.$route.query.rts,
        })
      } else {
        console.log("no query worth noting" ,this.$route.query);
      }
    },
  },
  created() {
    //do something after creating vue instance
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })

    this.checkQuery()

  },

  components: {
    Area,
  },
  metaInfo() {
    return {
      title: settings.TITLE, // set a title
      titleTemplate: '%s', // title is now "My Example App - Yay!"
      meta:[
        { vmid:'og:image', property: 'og:image', content: "https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_lpad,h_900,w_1400/v1545459923/Logo/Logo_square_LARGE_TXT_no_bg.jpg"},
        { vmid:'description', name:'description', content:settings.TAGLINE},
        { vmid:'identifier-url', name:'identifier-url',content:'https://myroomabroad.com/'+this.$router.path},
        { vmid:'keywords', name:'keywords', content:'My Room Abroad, Taiwan, Taipei, Rooms, Apartments, Studios, Rentals, Rent a room, Rent an apartment'},
      ]
    }
  },
}
</script>

<style lang="scss">
html,
body,
#app {
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}

.search-bar placehodler {
  padding: 15px
}
</style>
<style lang="scss" scoped>
.trust {
    margin-top: 10vh;
    margin-bottom: 3vh;
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}
.trust-logos a {
    color: rgba(255,255,255,1)!important;
    text-decoration: none;
}
.search-row {
    background: rgba(255,255,255,0),;
    background: rgba(102,102,102,0.5), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: -moz-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(102,102,102,0.5)), color-stop(40%, rgba(133,133,133,0)), color-stop(100%, rgba(255,255,255,0))), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: -webkit-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: -o-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: -ms-linear-gradient(top, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
    background: linear-gradient(to bottom, rgba(102,102,102,0.5) 0%, rgba(133,133,133,0) 40%, rgba(255,255,255,0) 100%), url("https://res.cloudinary.com/my-room-abroad/image/fetch/c_scale,q_auto,w_1440/https://res.cloudinary.com/my-room-abroad/image/upload/v1537411387/homepage/cover_picture.jpg") center/cover no-repeat;
}
.title {
    margin: auto;
    padding-top: 15vh;
}
.title h1 {
    text-align: center;
    color: #ffffff;
    text-shadow: 0 2px 4px rgba(0,0,0,.6);
}

.left-border{
  border-left-style: solid;
  border-left-width: thin;
  border-left-color: rgba(0,0,0,.09)
}
// .hp-search {
//     margin: 10vh auto;
//     padding: 3px 0 0 0 !important;
//
//     // background-color: #fff;
//     // border-width: 1px;
//     // border-style: solid;
//     // border-color:rgba(0,0,0,.15);
//     > div {
//         padding-left: 8px;
//     }
//     .box{
//       background-color: #fff;
//       border-width: 1px;
//       border-style: solid;
//       height:66px;
//       border-color:rgba(0,0,0,.15);
//       > div {
//         padding-top: 0px;
//       }
//       .v-input{
//         padding-top: 0px
//       }
//     }
// }
// .hp-search input {
//     color:rgba(0,0,0,.67);
//     width: 100%;
//     border: none;
//     font-size: 1rem;
//     margin: 0;
//     padding: 0;
//     &:focus {
//         outline: none;
//     }
// }
// .hp-search placeholder {
//     color: rgba(0,0,0,.38);
// }
//
.search-bar {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    margin: 10vh auto;
    padding-left: 100px;
    padding: 0 !important;
    background-color: #fff;
    border-radius:  4px;
    > div {
    }
    .margin-left{
      padding-left: 8px;

    }
}
.search-bar input {
    height: 68px;
    width: 100%;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}
.search-bar placeholder {
    color: rgba(0,0,0,.38);
}

.search-btn {
    height: 70px;
    margin: 0;
    border: none;
    width: 100%;
    text-transform: capitalize;
    border-radius: 4px;
    font-size: 1rem;
    float: right;
    align-items: center;
    justify-content: center;
    display: flex;
}

// Section 2: Booking steps:
.booking-steps {
    margin: auto;
    text-align: center;
    padding: 60px;
    padding-bottom:30px
}
.booking-steps-text {
    margin: auto;
}
.booking-steps-list-item {
    text-align: center;
    > p {
        text-align: center;
    }
}
.booking-steps-list-step-image {
    width: 100%;
    max-width: 200px;
    margin: 20px auto;

}
//section 3 data info:
.data-info {
    background: #00c7FF;
    padding: 50px 0;
    text-align: center;
}
//section 4 team:
.team-info {
    display: flex;
    align-items: center;
}
.team {
    padding: 100px 0;
}
.mra-team-pic {
    max-width: 100%;
}
.landlord-area {
    padding: 100px 0;
    text-align: center;
    margin: auto;
}
.no-overflow{
  overflow:hidden;
}
.trust h5,
h6 {
    color: #ffffff;
}

.dark-bg{
  color:#FFFFFF;
}
.subscribe h3 {
    color: #ffffff;
}
.landlord-area__heading{
  text-align: center;
}
@media only screen and (max-width: 600px) {
    .top-border-xs{
      border-top-color: rgba(0,0,0,.09);
      border-top-style: solid;
      border-top-width: thin;

    }

    .team {
        padding-bottom: 0;
    }
    .coupon-card{
      margin: 5vh auto ;
      max-width: 75%;
    }
    .title{
      padding-top:5vh!important;
    }
    .coupon-title{
      font-size:2em;
      line-height: 1.2em;
      color:rgba(0,0,0,.75);
      margin: 1em .6em;
    }
    .coupon-text{
      font-size:1.3em;
      color:rgba(0,0,0,1);
      margin: .6em ;
    }
    .coupon-deadline{
      font-size:1.2em;
      color:rgba(0,0,0,.6)
    }
    .coupon-link{
      color:rgba(0,0,0,.75)

    }
    .card-width{
      width:100%;
    }
}
.info-bar{
  text-align: center;
  background-color: #ff4000 ;
  color: #FFF;
}

@media only screen and (min-width: 600px) {
  .coupon-card{
    margin:30vh auto ;
    max-width: 75%;

  }
  .coupon-title{
    font-size:2em;
    color:rgba(0,0,0,.75);
    line-height:1.2em;
    margin: 1.2em .5em;
  }
  .coupon-text{
    font-size:1.3em;
    color:rgba(0,0,0,1);
    margin: .6em ;
  }
  .coupon-deadline{
    font-size:1.2em;
    color:rgba(0,0,0,.6)
  }
  .coupon-link{
    color:rgba(0,0,0,.75)

  }
  .card-width{
    width:100%;
    color:rgba(0,0,0,.75)
  }

  .card-width{
    width:75%;
  }
    .hide-on-large {
        display: none;
    }
}
</style>
